 /*eslint-disable no-unused-vars*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/logo.png";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Shops from "./ShopAdmin";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import StorefrontIcon from "@material-ui/icons/Storefront";
//import EditShop2 from "./EditShop2";
import Brightness4 from "@material-ui/icons/Brightness4";
import Brightness7 from "@material-ui/icons/Brightness7";
import Footer from "./ui/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    flexDirection: "column",    
    backgroundRepeat: "repeat",
  },
  logo: {
    height: 50,
  },
  iconButton: {
    borderRadius: 0,
  },
  iconButtonSelected: {
    borderRadius: 0,
    borderBottom: "5px solid #00efff",
  },  
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  growBottom: {
    height: "100vh",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  icon: {
    marginRight: 5,
  },
}));

const contents = {
  SHOPS: 0,
  EDIT_SHOPS: 1,
};

export default function Home(props) {
  const classes = useStyles();

  const [showShops, setShowShops] = useState(true);
  const [showEditShop, setShowEditShop] = useState(false);
  const [contentTitle, setContentTitle] = useState("Shopify Shops");
  const [selectedMenu, setSelectedMenu] = useState(contents.SHOPS);

  const [shop, setShop] = useState(null);

  const loadEditShop = (shop) => {
    activeContent(contents.EDIT_SHOPS);
    setShop(shop);
  };

  const activeContent = (content) => {
    setShowEditShop(false);
    setShowShops(false);
    setSelectedMenu(contents.SHOPS);

    switch (content) {
      case contents.SHOPS:
        setShowShops(true);
        setContentTitle("Shopify Shops");
        setSelectedMenu(contents.SHOPS);
        break;
      case contents.EDIT_SHOPS:
        setShowEditShop(true);
        setContentTitle("Editing Shopify Shops");
        setSelectedMenu(contents.EDIT_SHOPS);
        break;
      default:
        break;
    }
  };

  const showContent = () => {
    console.log("showShops:"+showShops)
    if (showShops) {
      return <Shops loadEditShop={loadEditShop} darkState={props.darkState} user={props.user} />
    } else if (showEditShop) {
      return null //<EditShop2 shop={shop} darkState={props.darkState} />
    }
  };

  const logOut = () => {
    var win = window.open("/", "_self");
    win.focus();
  };

  return (
    <div className={classes.root} style={{backgroundColor: `${props.darkState ? "#2b2b2b" : "#FFFFFF"}`}}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => activeContent(contents.SHOPS)}          
          >
            <img alt="Logo" className={classes.logo} src={logo} />
          </IconButton>          
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="show shopify shops"
              color="inherit"
              onClick={() => activeContent(contents.SHOPS)}
              className={
                selectedMenu === contents.SHOPS
                  ? classes.iconButtonSelected
                  : classes.iconButton
              }                 
            >
              <StorefrontIcon className={classes.icon} />
              <Typography>Shops</Typography>
            </IconButton>
            <Tooltip title={props.user.name} aria-label="account">
              <IconButton aria-label="show jobs history" color="inherit">
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <IconButton
              aria-label="log out"
              color="inherit"
              onClick={() => logOut()}
            >
              <ExitToApp className={classes.icon} />
              <Typography>Logout</Typography>
            </IconButton>
            <Tooltip title="Toggle light/dark theme" aria-label="Toggle theme">
              <IconButton
                aria-label="Toggle light/dark theme"
                color="inherit"
                onClick={() => props.handleThemeChange()}
              >
                {props.darkState ? <Brightness4 /> : <Brightness7 />}
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {showContent()}
      <Footer />
    </div>
  );
}
