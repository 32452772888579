import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import GoogleLogin from 'react-google-login';
import Footer from "./ui/Footer";
import logo from "../assets/logo.png";
import bg from "../assets/bg.jpg";
import Button from '@material-ui/core/Button';
import { CLIENT_ID } from './Constant';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",       
  },
  container: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",    
  },  
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column", 
    margin: "auto",
    alignContent: "center",  
    backgroundColor: "#fbfddf",    
    height: "200px",             
  },
  logo: {
    paddingBottom: theme.spacing(1),   
  }
}));

export default function Login(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
       <Container className={classes.container}>
          <Paper className={classes.paper} elevation={24}>            
            <img alt="UMG Datahub" className={classes.logo} src={logo} />
            <Box m="auto">
              <GoogleLogin
                  clientId={CLIENT_ID} 
                  buttonText="Login with umusic account"
                  onSuccess={props.loginSuccess}
                  onFailure={props.loginFailure}
                  render={renderProps => (
                    <Button variant="contained" size="large" color="primary" onClick={renderProps.onClick} style={{backgroundColor: "#D25532"}}>Login with UMG Google Account</Button>
                  )}
              />  
            </Box> 
          </Paper>
       </Container>
       <Footer />
    </div>
  );
}