/*eslint-disable no-unused-vars*/

import React, {useState} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import {Typography} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import loader from "../assets/loader.gif";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import ExtraFields from "./ExtraFields"
import EditShop from "./EditShop";

export default function Shops(props) {

    const [shopifyShops, setShopifyShops] = useState([]);

    const [shopifyLabels, setShopifyLabels] = useState([]);

    const [countries, setCountries] = useState([]);

    const [openExtraFields, setOpenExtraFields] = React.useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [openEditShop, setOpenEditShop] = React.useState(false);

    const handleClickOpen = (selectedRowData) => {
        setOpenExtraFields(true);
        setSelectedRowData(selectedRowData);
    };

    const handleClose = (value) => {
        setOpenExtraFields(false);
        setSelectedRowData(null);
    };

    const shopifyShopsQuery = () => {
        const SHOPIFY_SHOPS_QUERY = gql`
            {
                shopifyShops(input: { limit: 4000 }) {
                    id
                    name
                    domain
                    isBravado
                    label
                    canopusID
                    canopusArtistName
                    masterArtistID
                    masterArtistName
                    partyID
                    partyName
                    countryName
                    countryCode
                    toggleID
                    storeManager
                    epicRights
                    csEmail
                    client
                    musicOwner
                    merchOwner
                    fhManaged
                    gaEntity
                    zendeskCs
                    createdBy
                    createdAt
                    isActive
                    isProd
                    shopOffboarded
                    offboardedDate
                    ims
                    countryRoName
                    countryRoCode
                    labelRo
                    bqSync
                    sapId
                    secondLabelRo
                }
            }
        `;

        return SHOPIFY_SHOPS_QUERY;
    };

    const shopifyLabelsQuery = () => {
        const SHOPIFY_LABELS_QUERY = gql`
            {
                shopifyLabels {
                    label
                }
            }
        `;

        return SHOPIFY_LABELS_QUERY;
    };

    const countriesQuery = () => {
        const COUNTRIES_QUERY = gql`
            {
                countries {
                    code
                    name
                }
            }
        `;

        return COUNTRIES_QUERY;
    };

    const extractShopifyShops = (data) => {
        console.log("extractShopifyShops...");
        if (typeof data.shopifyShops === "undefined") return shopifyShops;

        return data.shopifyShops;
    };

    const extractShopifyLabels = (data) => {
        console.log("extractShopifyLabels...");
        if (typeof data.shopifyLabels === "undefined") return shopifyLabels;

        return data.shopifyLabels;
    };

    const extractCountries = (data) => {
        console.log("extractCountries...");
        if (typeof data.countries === "undefined") return countries;

        return data.countries;
    };

    const handleClickEdit = (selectedRowData) => {
        console.log("handleClickEdit!");
        if (!props.user.readonly) {
            setOpenEditShop(true);
            setSelectedRowData(selectedRowData);
        } else {
            console.log("edit is not allowed!");
        }

    };

    const {loading: loadingShopifyLabels, error: errorShopifyLabels} = useQuery(
        shopifyLabelsQuery(),
        {
            onCompleted: (data) => {
                console.log("shopifyLabelsQuery completed");
                setShopifyLabels(extractShopifyLabels(data));
            },
        }
    );

    const {loading: loadingCountries, error: errorCountries} = useQuery(
        countriesQuery(),
        {
            onCompleted: (data) => {
                console.log("countriesQuery completed");
                setCountries(extractCountries(data));
            },
        }
    );

    const {data: shopsData, loading: loadingShopifyShops, error: errorShopifyShops} = useQuery(
        shopifyShopsQuery(),
        {
            onCompleted: (data) => {
                console.log("shopifyShopsQuery completed");
                setShopifyShops(extractShopifyShops(data));
            },
            refetchOnWindowFocus: false,
            queryDeduplication: true,


            //fetchPolicy: "network-only",
            fetchPolicy: "cache-first",
            // nextFetchPolicy: "cache-first"

        },
    );
    if (loadingShopifyShops)
        return (
            <div style={{textAlign: "center"}}>
                <img alt="loading Shopify shops" src={loader}/>
            </div>
        );
    if (errorShopifyShops)
        return <Typography>Failed to fetch data from server.</Typography>;

    const showEditShop = () => {
        if (openEditShop) {
            console.log("Edit INDEX: ", selectedRowData.tableData.id);
            return <EditShop shop={selectedRowData} openEditShop={openEditShop}
                             handleCloseEditShop={handleCloseEditShop}
                             handleCloseEditShopUpdate={handleCloseEditShopUpdate} shopifyLabels={shopifyLabels}
                             countries={countries} user={props.user}/>
        } else if (showEditShop) {
            return null
        }
    };

    const handleCloseEditShop = (value) => {
        setOpenEditShop(false);
        setSelectedRowData(null);
    };

    const handleCloseEditShopUpdate = (updatedShop) => {
        console.log("handleCloseEditShopUpdate...")
        console.log("updatedShop :", updatedShop);
        if (shopsData) {
            var index = updatedShop.tableData.id;
            console.log("Update Index: ", updatedShop.tableData.id);
            shopsData.shopifyShops[index].label = updatedShop.label.label;
            shopsData.shopifyShops[index].canopusID = updatedShop.canopusID;
            shopsData.shopifyShops[index].canopusArtistName = updatedShop.canopusArtistName;
            shopsData.shopifyShops[index].masterArtistID = updatedShop.masterArtistID;
            shopsData.shopifyShops[index].masterArtistName = updatedShop.masterArtistName;
            shopsData.shopifyShops[index].partyID = updatedShop.partyID;
            shopsData.shopifyShops[index].partyName = updatedShop.partyName;
            shopsData.shopifyShops[index].isBravado = updatedShop.isBravado;
            shopsData.shopifyShops[index].toggleID = updatedShop.toggleID;
            shopsData.shopifyShops[index].storeManager = updatedShop.storeManager;
            shopsData.shopifyShops[index].epicRights = updatedShop.epicRights;
            shopsData.shopifyShops[index].csEmail = updatedShop.csEmail;
            shopsData.shopifyShops[index].client = updatedShop.client;
            shopsData.shopifyShops[index].musicOwner = updatedShop.musicOwner;
            shopsData.shopifyShops[index].merchOwner = updatedShop.merchOwner;
            shopsData.shopifyShops[index].gaEntity = updatedShop.gaEntity;
            shopsData.shopifyShops[index].isActive = updatedShop.isActive;
            shopsData.shopifyShops[index].isProd = updatedShop.isProd;
            shopsData.shopifyShops[index].countryName = updatedShop.countryName;
            shopsData.shopifyShops[index].countryCode = updatedShop.countryCode;
            shopsData.shopifyShops[index].shopOffboarded = updatedShop.shopOffboarded;
            shopsData.shopifyShops[index].ims = updatedShop.ims;
            shopsData.shopifyShops[index].countryRoName = updatedShop.countryRoName;
            shopsData.shopifyShops[index].countryRoCode = updatedShop.countryRoCode;
            shopsData.shopifyShops[index].labelRo = updatedShop.labelRo.label;
            shopsData.shopifyShops[index].bqSync = updatedShop.bqSync;
            shopsData.shopifyShops[index].sapId = updatedShop.sapId;
            shopsData.shopifyShops[index].secondLabelRo = updatedShop.secondLabelRo.label;
        }
        setOpenEditShop(false);
        setSelectedRowData(null);
    };

    return (
        <Container maxWidth="lg" style={{marginTop: "5px"}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MaterialTable
                        title="Shopify Shops"
                        columns={[
                            {
                                title: <Typography color="textPrimary">Name</Typography>,
                                field: "name",
                                render: (rowData) => (
                                    <Typography color="textPrimary">{rowData.name}</Typography>
                                ),
                            },
                            {
                                title: <Typography color="textPrimary">URL</Typography>,
                                field: "domain",
                                render: (rowData) => (
                                    <Typography color="textPrimary">

                                        <Button tar="_blank" href={"https://" + rowData.domain}
                                                style={{textTransform: 'none'}}>
                                            {rowData.domain}
                                        </Button>

                                    </Typography>
                                ),
                            },
                            {
                                title: <Typography color="textPrimary">Music Rights</Typography>,
                                field: "musicRights",
                                render: (rowData) => (
                                    <Typography color="textPrimary">{rowData.musicOwner}</Typography>
                                ),
                            },
                            {
                                title: <Typography color="textPrimary">Merch Rights</Typography>,
                                field: "merchRights",
                                render: (rowData) => (
                                    <Typography color="textPrimary">{rowData.merchOwner}</Typography>
                                ),
                            },
                            {
                                title: <Typography color="textPrimary">Territory</Typography>,
                                field: "territory",
                                width: 80,
                                render: (rowData) => (
                                    <Typography color="textPrimary">{rowData.countryName}</Typography>
                                ),
                            },
                            {
                                title: (
                                    <Typography color="textPrimary">Extra Fields</Typography>
                                ),
                                width: 160,
                                render: (rowData) => (
                                    <Typography color="textPrimary">
                                        <Link
                                            component="button"
                                            underline="always"
                                            onClick={() => handleClickOpen(rowData)}
                                        >
                                            click for details
                                        </Link>
                                    </Typography>
                                ),
                            },
                        ]}
                        data={shopsData.shopifyShops}
                        actions={[
                            {
                                icon: "edit",
                                iconProps: props.darkState
                                    ? {style: {color: "#0de433"}}
                                    : {style: {color: "#3F51B5"}},
                                tooltip: "Edit",
                                onClick: (event, rowData) => handleClickEdit(rowData),
                            },
                        ]}
                        options={{
                            search: true,
                            pageSize: 10,
                            sorting: true,
                            pageSizeOptions: [10, 100, 1000],
                            emptyRowsWhenPaging: false,
                            toolbarStyle: {
                                backgroundColor: '#01579b',
                                color: "textPrimary"
                            }
                        }}
                        localization={{
                            header: {
                                actions: <Typography color="textPrimary">Actions</Typography>,
                            },
                        }}
                    />
                </Grid>
                <ExtraFields data={selectedRowData} open={openExtraFields} onClose={handleClose}/>
                {showEditShop()}
            </Grid>
        </Container>
    );
}
 