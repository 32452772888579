import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();

theme.typography.body2 = {
  fontSize: '1.2rem',
  '@media (max-width:600px)': {
    fontSize: 6,
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: 10,
  },
};

const MyTypography = withStyles({
  root: {
    color: "#908373"
  }
})(Typography);

function Copyright() {
  return (
      <MyTypography variant="body2" style={{ letterSpacing: "0.0625em" }} >
        Created in LA with <b style={{ color: '#D64292' }}>&nbsp;♡&nbsp;</b> by Data Engineering | UMG Global Tech | UMG © 2020. ♪ ♫
      </MyTypography>
  );
}

const useStyles = makeStyles(theme => ({
    footer: {
        padding: theme.spacing(1, 1),
        width: "100%",
        zIndex: 1202,
        position: "relative",
        display: "flex",
        justifyContent: "center",
    }, 
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
        <Copyright />
    </footer>
  );
}