import React from "react";
import {ThemeProvider} from "@material-ui/styles";
import theme from "./ui/Theme";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ApolloClient from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloProvider} from "@apollo/react-hooks";
import Welcome from "./Welcome";

const link = createHttpLink({
    uri: "/hub",
    // uri: "http://localhost:8080/hub",
    // headers:"Access-Control-Allow-Origin"
});

/*
const wsLink = new WebSocketLink({
  uri: "ws://localhost:8080/hub",
  options: {
    reconnect: true
  }
});


const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
*/

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,

    defaultOptions: {
      mutate: {
        errorPolicy: 'ignore' // TODO ignore DD error for now
      },
    }

});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" render={(props) => <Welcome {...props} />}/>
                    </Switch>
                </BrowserRouter>
            </ApolloProvider>
        </ThemeProvider>
    );
}

export default App;
