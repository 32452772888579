import { createTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

export default createTheme({
    overrides: {
        MuiListItem: {
            root: {
                '&$selected': {
                backgroundColor: indigo.A100,
            },         
        },
      },
    }, 
    palette: {
        //alternateTextColor: Colors.redA200
    },
});
