/*eslint-disable no-unused-vars*/

import React, {useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {useMutation} from "@apollo/react-hooks";
import Switch from "@material-ui/core/Switch";
import {gql} from "apollo-boost";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import * as EmailValidator from 'email-validator';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    shopName: {
        marginLeft: theme.spacing(2),
    },
    syncStartGrid: {
        position: "relative",
        bottom: "4px",
    },
    syncStart: {
        width: "25ch",
    },
    isBravadoGrid: {
        position: "relative",
        top: "10px",
    },
    isBravado: {
        marginLeft: theme.spacing(1),
    },
    closeButton: {
        margin: theme.spacing(1),
    },
    saveButton: {
        margin: theme.spacing(1),
    },
    customInt: {
        width: "25ch",
    },
    customVarchar: {
        width: "35ch",
    },
    textInput: {
        width: "40ch",
        marginLeft: theme.spacing(2),
    },
    artists: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(5),
    },
    labelAutocomplete: {
        width: "40ch",
        marginLeft: theme.spacing(2),
    },
    countryAutocomplete: {
        width: "35ch",
        marginLeft: theme.spacing(2),
    },
    dialog: {
        width: '99%',
        maxWidth: "870px",
        maxHeight: "95%",
    },
}));

const AntSwitch = withStyles((theme) => ({
    root: {},
    switchBase: {},
    thumb: {},
    track: {},
    checked: {},
}))(Switch);

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function EditShop(props) {
    const classes = useStyles();

    const [shop, setShop] = useState(props.shop);

    const [country, setCountry] = useState(props.country);

    // const [ims, setIms] = useState(shop.ims);

    var artistsArrayProp = [];
    if (props.shop.masterArtistID) {
        var masterArtistID = props.shop.masterArtistID.split(",");
        var masterArtistName = props.shop.masterArtistName.split(",");
        if(props.shop.partyID) {
            var partyID= props.shop.partyID.split(",");
            var partyName= props.shop.partyName.split(",");
        }
        for (let i = 0; i < masterArtistID.length; i++) {
            var artistEntry = {
                master_artist_id: masterArtistID[i],
                master_artist: masterArtistName[i],
                party_id: partyID[i],
                party_name: partyName[i],
            };
            // var artistPartyEntry = {
            //     party_id: partyID[i],
            //     party_name: partyName[i],
            // };
            artistsArrayProp.push(artistEntry);
            // artistsPartyArrayProp.push(artistPartyEntry);
            // if(partyID){
            //     var artistPartyEntry = {
            //         party_id: partyID[i],
            //         party_name: partyName[i],
            //     };
            //
            // }

        }
    }

    const [artists, setArtists] = useState([]);
    const [artistsArray, setArtistsArray] = useState(artistsArrayProp ? artistsArrayProp : []);
    const [artistsSelectError, setArtistsSelectError] = useState(false);
    const [imsSelectError, setImsSelectError] = useState(false);
    const [labelSelectError, setLabelSelectError] = useState(false);
    const [emailInputError, setEmailInputError] = useState(false);
    const [artistData, setArtistData] = useState(null);
    const [labelRoSelectError, setLabelRoSelectError] = useState(false);
    const [countryRoSelectError, setCountryRoSelectError] = useState(false);


    const artistsArrayChange = (_, value) => {
        //console.log("artistsArrayChange value: ", value);
        if (value.length > 0) setArtistsSelectError(false);
        setArtistsArray(value);
        var masterArtistIDs = [];
        var masterArtistNames = [];
        var partyIDs = [];
        var partyNames = [];
        for (let i = 0; i < value.length; i++) {
            masterArtistIDs.push(value[i].master_artist_id);
            masterArtistNames.push(value[i].master_artist);
            partyIDs.push(value[i].party_id);
            // Change from party name to default name
            partyNames.push(value[i].master_artist);
        }
        var masterArtistIDCsv = masterArtistIDs.join();
        var masterArtistNameCsv = masterArtistNames.join();

        var partyIDCsv = partyIDs.join();
        var partyNameCsv = partyNames.join();

        setShop((prevShop) => ({
            ...prevShop,
            masterArtistID: masterArtistIDCsv,
            masterArtistName: masterArtistNameCsv,
            partyID: partyIDCsv,
            partyName: partyNameCsv,
        }));
    };

    const artistsOnInputChange = (_, value) => {
        //console.log("artistsOnInputChange value: ", value);

        if (!value) return
        axios.get(`/artist`, {
            params: {
                key: value
            }
        })
            .then(res => {
                // console.log(res);
                setArtistData(res.data.GetHubArtists.rollupArtists ? res.data.GetHubArtists.rollupArtists : null);
            })
    };

    const labelChange = (_, value) => {
        var newLabel = value;
        setLabelSelectError(false);
        setShop((prevShop) => ({
            ...prevShop,
            label: newLabel ? newLabel.label : null,
        }));
    };

    const labelRoChange = (_, value) => {
        console.log("labelRoChange")
        var newLabelRo = value;
        setLabelRoSelectError(false);
        setShop((prevShop) => ({
            ...prevShop,
            labelRo: newLabelRo ? newLabelRo.label : null,
        }));
    };
    const secondLabelRoChange = (_, value) => {
        console.log("secondLabelRoChange")
        var newSecondLabelRo = value;
        setShop((prevShop) => ({
            ...prevShop,
            secondLabelRo: newSecondLabelRo ? newSecondLabelRo.label : null,
        }));
    };
    const countryChange = (_, value) => {
        var newCountry = value;
        setShop((prevShop) => ({
            ...prevShop,
            countryName: newCountry ? newCountry.name : null,
            countryCode: newCountry ? newCountry.code : null,
        }));
    };

    const countryRoChange = (_, value) => {
        var newCountry = value;
        setShop((prevShop) => ({
            ...prevShop,
            countryRoName: newCountry ? newCountry.name : null,
            countryRoCode: newCountry ? newCountry.code : null,
        }));
    };

    const handleTextInputChange = (event) => {
        const {name, value} = event.target;
        console.log("NAME: ", name)
        console.log("value: ", value)
        setShop((prevShop) => ({
            ...prevShop,
            [name]: value,
        }));
    };

    const stringToDate = (dateString) => {
        return new Date(dateString + "T00:00:00");
    }

    const dateToString = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleOffboardedDateChange = (date) => {
        let dateString = dateToString(date)
        console.log("date string: ", dateString);
        console.log("Offboarded: ", shop.shopOffboarded);
        setShop((prevShop) => ({
            ...prevShop,
            offboardedDate: dateString,
            shopOffboarded: true,
        }));
        console.log("Offboarded: ", shop.shopOffboarded);
    };

    const handleImsChange = (event) => {
        console.log("ims target value: ", event.target.value);
        setShop((prevShop) => ({
            ...prevShop,
            ims: event.target.value,

        }));
        // setIms((newIms) => event.currentTarget.value);
        setImsSelectError(false);
    };

    const toggleBravado = () => {
        console.log("toggleBravado...");
        var newValue = shop.isBravado === null ? false : !shop.isBravado;
        setShop((prevShop) => ({
            ...prevShop,
            isBravado: newValue,
        }));
    };

    const toggleEpicRights = () => {
        console.log("toggleEpicRights...");
        var newValue = shop.epicRights === null ? false : !shop.epicRights;
        setShop((prevShop) => ({
            ...prevShop,
            epicRights: newValue,
        }));
    };

    const toggleBqSync = () => {
        console.log("toggleBqSync...");
        var newValue = shop.bqSync === null ? true : !shop.bqSync;
        setShop((prevShop) => ({
            ...prevShop,
            bqSync: newValue,
        }));
        console.log("new bq sync")
    };
    const toggleActive = () => {
        console.log("toggleActive...");
        var newValue = shop.isActive === null ? false : !shop.isActive;
        setShop((prevShop) => ({
            ...prevShop,
            isActive: newValue,
        }));
    };

    const toggleProd = () => {
        console.log("toggleProd...");
        var newValue = shop.isProd === null ? false : !shop.isProd;
        setShop((prevShop) => ({
            ...prevShop,
            isProd: newValue,
        }));
    };

    const toggleShopOffboarded = () => {
        console.log("toggleShopOffboarded...");
        var newValue = shop.shopOffboarded === null ? true : !shop.shopOffboarded;

        if (!newValue) {
            setShop((prevShop) => ({
                ...prevShop,
                offboardedDate: null,
            }));
        }

        setShop((prevShop) => ({
            ...prevShop,
            shopOffboarded: newValue,
        }));
    };

    const CREATE_SHOPIFY_SHOP = gql`
        mutation createShopifyShop(
            $id: Int!
            $name: String!
            $domain: String!
            $isBravado: Boolean
            $label: String
            $canopusID: String
            $canopusArtistName: String
            $masterArtistID: String
            $masterArtistName: String
            $partyID: String
            $partyName: String
            $toggleID: Int
            $storeManager: String
            $epicRights: Boolean
            $csEmail: String
            $client: String
            $musicOwner: String
            $merchOwner: String
            $gaEntity: String
            $createdBy: String!
            $isActive: Boolean = true
            $isProd: Boolean = true
            $countryCode: String
            $countryName: String
            $fhManaged: String
            $zendeskCs: String
            $shopOffboarded: Boolean = false
            $offboardedDate: String
            $ims: String!
            $countryRoCode: String!
            $countryRoName: String!
            $labelRo:  String!
            $bqSync: Boolean = true
            $sapId: String
            $secondLabelRo:  String!
        ) {
            createShopifyShop(
                input: {
                    id: $id
                    name: $name
                    domain: $domain
                    isBravado: $isBravado
                    label: $label
                    canopusID: $canopusID
                    canopusArtistName: $canopusArtistName
                    masterArtistID: $masterArtistID
                    masterArtistName: $masterArtistName
                    partyID: $partyID
                    partyName: $partyName
                    toggleID: $toggleID
                    storeManager: $storeManager
                    epicRights: $epicRights
                    csEmail: $csEmail
                    client: $client
                    musicOwner: $musicOwner
                    merchOwner: $merchOwner
                    gaEntity: $gaEntity
                    createdBy: $createdBy
                    isActive: $isActive
                    isProd: $isProd
                    countryCode: $countryCode
                    countryName: $countryName
                    fhManaged: $fhManaged
                    zendeskCs: $zendeskCs
                    shopOffboarded: $shopOffboarded
                    offboardedDate: $offboardedDate
                    ims: $ims
                    countryRoCode: $countryRoCode
                    countryRoName: $countryRoName
                    labelRo: $labelRo
                    bqSync: $bqSync
                    sapId: $sapId
                    secondLabelRo: $secondLabelRo
                }
            ) {
                id
                name
                domain
                isBravado
                label
                canopusID
                canopusArtistName
                masterArtistID
                masterArtistName
                partyID
                partyName
                toggleID
                storeManager
                epicRights
                csEmail
                client
                musicOwner
                merchOwner
                gaEntity
                createdBy
                createdAt
                isActive
                isProd
                countryCode
                countryName
                fhManaged
                zendeskCs
                shopOffboarded
                offboardedDate
                ims
                countryRoCode
                countryRoName
                labelRo
                bqSync
                sapId
                secondLabelRo
                __typename
            }
        }
    `;

    const [createShopifyShop, {data: createdShop}] = useMutation(
        CREATE_SHOPIFY_SHOP,
        {
            onCompleted(data) {
                //console.log(data);
                console.log("Saved shop");
            },
        }
    );

    const saveChanges = () => {
        console.log("saveChanges...");
        //console.log("Saving: ", shop);
        var isValid = true;
        if (!shop.label) {
            setLabelSelectError(true);
            // console.log("label: ", shop.label);
            isValid = false;
        }
        if (!shop.labelRo) {
            setLabelRoSelectError(true);
            isValid = false;
            // console.log("labelRo: ", shop.labelRo);
        }
        if (!shop.countryRoCode) {
            setCountryRoSelectError(true);
            // console.log("countryRoCode: ", shop.countryRoCode);
            isValid = false;
        }
        if (!shop.ims) {
            setImsSelectError(true);
            // console.log("countryRoCode: ", shop.countryRoCode);
            isValid = false;
        }

        if (artistsArray.length === 0) {
            setArtistsSelectError(true);
            isValid = false;
        }

        if (shop.csEmail && !EmailValidator.validate(shop.csEmail)) {
            setEmailInputError(true);
            isValid = false;
        }
        console.log(" shop.secondLabelRo: ",  shop.secondLabelRo);
        console.log("isValid: ", isValid);
        if (!isValid) return;
        createShopifyShop({
            variables: {
                id: shop.id,
                name: shop.name,
                domain: shop.domain,
                isBravado: shop.isBravado === null ? true : shop.isBravado,
                label: shop.label,
                canopusID: shop.canopusID,
                canopusArtistName: shop.canopusArtistName,
                masterArtistID: shop.masterArtistID,
                masterArtistName: shop.masterArtistName,
                partyID: shop.partyID,
                partyName: shop.partyName,
                toggleID: shop.toggleID,
                storeManager: shop.storeManager,
                epicRights: shop.epicRights === null ? true : shop.epicRights,
                csEmail: shop.csEmail,
                client: shop.client,
                musicOwner: shop.musicOwner,
                merchOwner: shop.merchOwner,
                gaEntity: shop.gaEntity,
                createdBy: props.user.email, //'alan.ma@umusic.com',
                isActive: shop.isActive === null ? true : shop.isActive,
                isProd: shop.isProd === null ? true : shop.isProd,
                countryCode: shop.countryCode,
                countryName: shop.countryName,
                fhManaged: shop.fhManaged,
                zendeskCs: shop.zendeskCs,
                shopOffboarded: shop.shopOffboarded === null ? false : shop.shopOffboarded,
                offboardedDate: shop.offboardedDate,
                ims: shop.ims,
                labelRo: shop.labelRo,
                countryRoCode: shop.countryRoCode,
                countryRoName: shop.countryRoName,
                bqSync: shop.bqSync === null ? true : shop.bqSync,
                sapId: shop.sapId === null ? '' : shop.sapId,
                secondLabelRo: shop.secondLabelRo=== null ? '' : shop.secondLabelRo,

            },
        });

        props.handleCloseEditShop(shop);
    };

    return shop ? (
        <Dialog
            onClose={props.handleCloseEditShop}
            aria-labelledby="extra-fields-dialog-title"
            open={props.openEditShop}
            classes={{paper: classes.dialog}}
        >
            <DialogTitle id="extra-fields-dialog-title">{shop.name}</DialogTitle>
            <DialogContent dividers>
                <Container
                    maxWidth="lg"
                    style={{marginTop: "20px"}}
                    className={classes.container}
                >
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography component={"span"}>
                                    <Grid
                                        component="label"
                                        container
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <FormControl
                                                required
                                                className={classes.labelAutocomplete}
                                            >
                                                <Autocomplete
                                                    id="label-select"
                                                    options={props.shopifyLabels || []}
                                                    getOptionLabel={(shopifyLabel) => shopifyLabel.label}
                                                    value={shop.label ? {"label": shop.label} : null}
                                                    onChange={labelChange}
                                                    getOptionSelected={(option, value) => value.label === option.label}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            variant="standard"
                                                            label="Label"
                                                            placeholder=""
                                                            error={labelSelectError}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl
                                                className={classes.countryAutocomplete}
                                            >
                                                <Autocomplete
                                                    id="country-select"
                                                    options={props.countries || []}
                                                    getOptionLabel={(country) => country.name}
                                                    value={shop.countryName ? {
                                                        "code": shop.countryCode,
                                                        "name": shop.countryName
                                                    } : null}
                                                    onChange={countryChange}
                                                    getOptionSelected={(option, value) => value.name === option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Territory"
                                                            placeholder=""
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component={"span"}>
                                    <Grid
                                        component="label"
                                        container
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Grid item xs={6}>
                                            <FormControl
                                                required
                                                className={classes.labelAutocomplete}
                                            >
                                                <Autocomplete
                                                    id="label-ro-select"
                                                    options={props.shopifyLabels || []}
                                                    getOptionLabel={(shopifyLabel) => shopifyLabel.label}
                                                    value={shop.labelRo ? {"label": shop.labelRo} : null}
                                                    onChange={labelRoChange}
                                                    getOptionSelected={(option, value) => value.label === option.label}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required
                                                            {...params}
                                                            variant="standard"
                                                            label="Label Ro"
                                                            placeholder=""
                                                            error={labelRoSelectError}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl
                                                className={classes.countryAutocomplete}
                                            >
                                                <Autocomplete
                                                    id="country-ro-select"
                                                    options={props.countries || []}
                                                    getOptionLabel={(country) => country.name}
                                                    value={shop.countryRoName ? {
                                                        "code": shop.countryRoCode,
                                                        "name": shop.countryRoName
                                                    } : null}
                                                    onChange={countryRoChange}
                                                    getOptionSelected={(option, value) => value.name === option.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            label="Territory Ro"
                                                            placeholder=""
                                                            error={countryRoSelectError}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    className={classes.artists}
                                    id="artists-select"
                                    multiple
                                    //options={artistData ? artistData.getArtists.artists : []}
                                    options={artistData ? artistData : []}
                                    getOptionLabel={(artist) => artist.master_artist}
                                    value={artistsArray}
                                    onChange={artistsArrayChange}
                                    onInputChange={artistsOnInputChange}
                                    freeSolo={true}
                                    getOptionSelected={(option, value) =>
                                        value.master_artist_id === option.master_artist_id
                                    }

                                    renderOption={( option) => (
                                        <li >
                                            <img
                                                loading="lazy"
                                                width="40"
                                                src={`${option.image1}`}
                                                srcSet={`${option.image1}`}
                                                alt=""
                                            />
                                            <b>{option.master_artist}</b> (<b>Master Id</b>:{option.master_artist_id} &nbsp;<b> Party Name:</b> {option.party_name} &nbsp;<b> Party Id:</b> {option.party_id})
                                        </li>

                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            required
                                            {...params}
                                            variant="standard"
                                            label="Artists"
                                            placeholder=""
                                            error={artistsSelectError}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="toggle-ID"
                                    label="Toggle ID"
                                    className={classes.textInput}
                                    value={shop.toggleID ? shop.toggleID : ""}
                                    name="toggleID"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="store-manager"
                                    label="Store Manager"
                                    className={classes.textInput}
                                    value={shop.storeManager ? shop.storeManager : ""}
                                    name="storeManager"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="customer-support-email"
                                    label="Customer Support Email"
                                    className={classes.textInput}
                                    value={shop.csEmail ? shop.csEmail : ""}
                                    name="csEmail"
                                    onChange={handleTextInputChange}
                                    error={emailInputError}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="client"
                                    label="Client"
                                    className={classes.textInput}
                                    value={shop.client ? shop.client : ""}
                                    name="client"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="music-owner"
                                    label="Music Rights"
                                    className={classes.textInput}
                                    value={shop.musicOwner ? shop.musicOwner : ""}
                                    name="musicOwner"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="merch-owner"
                                    label="Merch Rights"
                                    className={classes.textInput}
                                    value={shop.merchOwner ? shop.merchOwner : ""}
                                    name="merchOwner"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="fh-managed"
                                    label="FH Managed"
                                    className={classes.textInput}
                                    value={shop.fhManaged ? shop.fhManaged : ""}
                                    name="fhManaged"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="ga-entity"
                                    label="GA Entity"
                                    className={classes.textInput}
                                    value={shop.gaEntity ? shop.gaEntity : ""}
                                    name="gaEntity"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="zendesk-cs"
                                    label="Zendesk CS"
                                    className={classes.textInput}
                                    value={shop.zendeskCs ? shop.zendeskCs : ""}
                                    name="zendeskCs"
                                    onChange={handleTextInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="dense"
                                        id="shop-offboarded-date-inline"
                                        label="Shop Offboarded Date"
                                        value={shop.offboardedDate ? stringToDate(shop.offboardedDate) : null}
                                        onChange={handleOffboardedDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        style={{marginTop: "2px"}}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="shop-id"
                                    label="Shop Id"
                                    readOnly={true}
                                    className={classes.textInput}
                                    value={shop.id ? shop.id : ""}
                                    name="shopId"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="sap-id"
                                    label="SAP Id"
                                    readOnly={true}
                                    className={classes.textInput}
                                    value={shop.sapId ? shop.sapId : ""}
                                    name="sapId"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    required
                                    className={classes.labelAutocomplete}
                                >
                                    <Autocomplete
                                        id="second-label-ro-select"
                                        options={props.shopifyLabels || []}
                                        getOptionLabel={(shopifyLabel) => shopifyLabel.label}
                                        value={shop.secondLabelRo ? {"label": shop.secondLabelRo} : null}
                                        onChange={secondLabelRoChange}
                                        getOptionSelected={(option, value) => value.secondLabel === option.secondLabel}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Second Label Ro"
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xs={4} align="left">
                                <Typography component={"span"}>
                                    <Grid
                                        component={"span"}
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <AntSwitch
                                                checked={shop.epicRights === null ? true : shop.epicRights}
                                                onChange={toggleEpicRights}
                                                name="epicSwitch"
                                                color="primary"
                                                className={classes.isBravado}
                                            />
                                        </Grid>
                                        <Grid item>Epic Rights</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align="left">
                                <Typography component={"span"}>
                                    <Grid
                                        component={"span"}
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <AntSwitch
                                                checked={shop.bqSync === null ? true : shop.bqSync}
                                                onChange={toggleBqSync}
                                                name="bqSyncSwitch"
                                                color="primary"
                                                className={classes.isBravado}
                                            />
                                        </Grid>
                                        <Grid item>BigQuery<br/>Sync</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align="left">
                                <Typography component={"span"}>
                                    <Grid
                                        component="label"
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <AntSwitch
                                                checked={shop.isBravado === null ? true : shop.isBravado}
                                                onChange={toggleBravado}
                                                name="BravadoSwitch"
                                                color="primary"
                                                className={classes.isBravado}
                                            />
                                        </Grid>
                                        <Grid item>Bravado</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={3} align="left">
                                <Typography component={"span"}>
                                    <Grid
                                        component={"span"}
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <AntSwitch
                                                checked={shop.isActive === null ? true : shop.isActive}
                                                onChange={toggleActive}
                                                name="activeSwitch"
                                                color="primary"
                                                className={classes.isBravado}
                                            />
                                        </Grid>
                                        <Grid item>Active</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={4} align="left">
                                <Typography component={"span"}>
                                    <Grid
                                        component={"span"}
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <AntSwitch
                                                checked={shop.isProd === null ? true : shop.isProd}
                                                onChange={toggleProd}
                                                name="prodSwitch"
                                                color="primary"
                                                className={classes.isBravado}
                                            />
                                        </Grid>
                                        <Grid item>PROD</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} align="left">
                                <Typography component={"span"}>
                                    <Grid
                                        component={"span"}
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <AntSwitch
                                                checked={shop.shopOffboarded === null ? false : shop.shopOffboarded}
                                                onChange={toggleShopOffboarded}
                                                name="prodSwitch"
                                                color="primary"
                                                className={classes.isBravado}
                                            />
                                        </Grid>
                                        <Grid item>Shop Offboarded</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component={"span"}>
                                    <Grid
                                        component={"span"}
                                        container
                                        alignItems="center"
                                        spacing={0}
                                        className={classes.isBravadoGrid}
                                    >
                                        <Grid item>
                                            <FormControl component="fieldset" className={classes.isBravadoGrid}>
                                                <FormLabel component="legend" required="true"
                                                           error={imsSelectError}>IMS</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-label="IMS"
                                                    aria-required="true"
                                                    value={shop.ims}
                                                    onChange={handleImsChange}
                                                    name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value="Shiphero" control={<Radio/>}
                                                                      label="Shiphero"/>
                                                    <FormControlLabel value="DOMS" control={<Radio/>} label="DOMS"/>
                                                    <FormControlLabel value="Shopify" control={<Radio/>}
                                                                      label="Shopify"/>
                                                    <FormControlLabel value="MerchToday" control={<Radio/>}
                                                                      label="MerchToday"/>
                                                    <FormControlLabel value="Staging" control={<Radio/>}
                                                                      label="Staging"/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </DialogContent>
            <DialogActions style={{margin: "0 center"}}>
                <Button autoFocus variant="outlined" onClick={() => props.handleCloseEditShop()} color="primary">
                    Cancel
                </Button>
                <Button variant="outlined" onClick={() => saveChanges()} color="secondary">
                    Save changes
                </Button>
            </DialogActions>
        </Dialog>
    ) : (
        <p>Loading shop: {JSON.stringify(props)}</p>
    );
}