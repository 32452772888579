import React from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Container from "@material-ui/core/Container";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    dailog: {
        width: '80%',
        maxHeight: 435,
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function ExtraFields(props) {
    const classes = useStyles();
    const {onClose, data, open} = props;

    const handleClose = () => {
        onClose();
    };

    return data ? (
        <Dialog
            onClose={handleClose}
            aria-labelledby="extra-fields-dialog-title"
            open={open}
            classes={{paper: classes.dialog}}
        >
            <DialogTitle id="extra-fields-dialog-title">{data.name}</DialogTitle>
            <DialogContent dividers>
                <Container
                    maxWidth="lg"
                    className={classes.container}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Shop Id: </strong>
                                {data.id}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Artists: </strong>
                                {data.masterArtistName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Artists Master ID: </strong>
                                {data.masterArtistID}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Artists Party ID: </strong>
                                {data.partyID}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Artists Party Name: </strong>
                                {data.partyName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Music Rights: </strong>
                                {data.musicOwner}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Merch Rights: </strong>
                                {data.merchOwner}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Territory: </strong>
                                {data.countryName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Label: </strong>
                                {data.label}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Toggle ID: </strong>
                                {data.toggleID}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Store Manager: </strong>
                                {data.storeManager}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Customer Support Email: </strong>
                                {data.csEmail}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Client: </strong>
                                {data.client}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Bravado: </strong>
                                {data.isBravado ? 'Yes' : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Epic Rights: </strong>
                                {data.epicRights ? 'Yes' : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>FH Managed: </strong>
                                {data.fhManaged}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>GA Entity: </strong>
                                {data.gaEntity}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Zendesk CS: </strong>
                                {data.zendeskCs}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Active: </strong>
                                {data.isActive ? 'Yes' : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>PROD: </strong>
                                {data.isProd ? 'Yes' : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Shop Offboarded: </strong>
                                {data.shopOffboarded ? 'Yes' : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Offboarded Date: </strong>
                                {data.offboardedDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>IMS: </strong>
                                {data.ims}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Territory RO: </strong>
                                {data.countryRoName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Label RO: </strong>
                                {data.labelRo}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Second Label RO: </strong>
                                {data.secondLabelRo}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>BQ Sync: </strong>
                                {data.bqSync === true ? 'Yes' : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>SAP Id: </strong>
                                {data.sapId}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions align="center">
                <Button autoFocus variant="outlined" onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
