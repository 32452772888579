/*eslint-disable no-unused-vars*/
import React, {useState} from 'react';
import Login from './Login';
import Home from './Home';
import Cookies from 'universal-cookie';
import {blueGrey, deepOrange, indigo, red} from "@material-ui/core/colors";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {CLIENT_ID} from './Constant';

const emails = new Set([
    "hemankumar.ganesan@umusic.com",
    "raj.gopal.tallapalli@umusic.com",
    "narendar.veerapalli@umusic.com",
    "murgesh.ramasamy@umusic.com",
    "ming.ye@umusic.com",
    "julia.bohn@umusic.com",
    "oliver.teng@umusic.com",
    "vijay.arumugaperumal@umusic.com",
    "tapan.thakur@umusic.com",
    "brett.medeiros@umusic.com",
    "kelsie.patterson@umusic.com",
    "devin.mabra@umusic.com",
    "dan.conway@umusic.com",
    "tyler.phillips@umusic.com",
    "josh.wolfberg@umusic.com",
    "evan.doremus@umusic.com",
    "saikrishna.balijepalli@umusic.com",
    "dinesh.kumar@umusic.com",
    "naveen.soundararajan@umusic.com",
    "hemankumar.ganesan@umusic.com",
    "kevin.mendez@umusic.com",
    "darwin.a@umusic.com",
    "jayaprakash.ramadoss@umusic.com",
    "alex.ocko@umusic.com",
    "jungbin.lim@umusic.com",
    "santosh.shripad.hegde@umusic.com",
    "subhash.divakar@umgconsult.com",
    "tom.swindells@umusic.com",
    "giulia.tomasiello@umusic.com",
    "dominik.horb@umusic.com",
    "roland.florstedt@umusic.com",
    "mcdonald.avwevuruo@umusic.com",
    "nithin.sam@umusic.com",
    "madhurya.malladi@umusic.com",
]);


const readOnlyEmails = new Set([
    "dominik.horb@umusic.com",
    "roland.florstedt@umusic.com",
]);


export default function Welcome(props) {
    const [darkState, setDarkState] = useState(false);
    const palletType = darkState ? "dark" : "light";
    const mainPrimaryColor = darkState ? blueGrey[500] : indigo[500];
    const mainSecondaryColor = darkState ? deepOrange[500] : red['A400'];
    const darkTheme = createTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            }
        }
    });

    const handleThemeChange = () => {
        setDarkState(!darkState);
    };

    const [user, setUser] = useState(null);

    const loginSuccess = (res) => {
        console.log("Login Success...")
        if (emails.has(res.profileObj.email.toLowerCase())) {
            const cookies = new Cookies();
            cookies.set('clientId', CLIENT_ID);
            cookies.set('tokenId', res.tokenId);
            let profile = res.profileObj;
            if (readOnlyEmails.has(res.profileObj.email.toLowerCase())) {
                profile.readonly=true;
            }
            console.log('cookies set');
            setUser(res.profileObj);
        } else {
            setUser(null);
            console.log("User not added!")
        }


    }

    const loginFailure = (res) => {
        console.log("loginFailure...")

        console.log("res: ", res)
        setUser(null);
    }


    return (
        <ThemeProvider theme={darkTheme}>
            <div>
                {
                    user ?
                        <Home user={user} darkState={darkState} handleThemeChange={handleThemeChange}/>
                        :
                        <Login loginSuccess={loginSuccess} loginFailure={loginFailure}/>
                }
            </div>
        </ThemeProvider>
    );
}  